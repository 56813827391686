<template>
  <div class="container page-global">
    <img class="home" alt="Cashless FYI" style="width:80vw;max-width:800px;" src="../assets/cashlessfyi-logo-512.png">
    <h1>Resources</h1>
    <p>Our menu at the top provides some additional resources.</p>

    <router-link to="/calculators"><div style="font-size:2em;text-decoration:underline;">Visit the ROI Calculators</div></router-link>
    <!-- <b-row>
      <b-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-3">
        <h2>ROI Calculators</h2>
        <div class="list-group" >
          <router-link class="list-group-item list-group-item-action" :to="{name:'calculators'}">ROI Calculators</router-link> 
        </div>
      </b-col>
    </b-row> -->
    <div class="mt-5">
      <a :href="calculators"><img src="../assets/logo.png" ></img></a>
      <div>No Cash Zone</div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import providers from "../data/providers.json";


export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      providers: providers
    }
  },
  methods: {
    providerList( listType )  {
      let l = this.providers.filter( (p) => { return p.markets.indexOf( listType ) >= 0 });
      return l;
    }
  }
}
</script>
<style lang="scss">


</style>
