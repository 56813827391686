import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/app.scss'

const { version, versionReleaseDate } = require('../package.json');

// Here is something to consider:  https://www.vuescript.com/mobile-ui-vant/

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  data: {   // app global 
    appVersion: version   
  },
  render: h => h(App)
}).$mount('#app')
